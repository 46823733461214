@import '/src/styles/color';
@import '/src/styles/mixin';

.banner {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  overflow: hidden;
  position: relative;
  background-color: #FDAE58;
  border-radius: 12px;
  gap: 10px;
}

.bgPromotionBanner {
  position: absolute;
  top: -64px;
  left: 0;
  width: 232px;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.gif {
  position: absolute;
  bottom: 52px;
  right: -28px;
  height: 100px;
  object-fit: cover;
  z-index: 1;
}

.btnClose {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: $color-orange-400;
  outline: none;
  border: none;
  box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);
  transition: all 0.3s ease-in-out;

  svg {
    color: #fff;
    flex-shrink: 0;
    width: 12px;
  }

  &:hover {
    background: $color-orange-300;
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  align-items: center;
  z-index: 2;
}

.content_text {
  text-align: center;
  flex: 1;
  width: 100%;

  color: $color-neutral-theme-900;

  .textFee {
    color: $color-positive-800;
  }
}

.content_popper {
  width: 33.05px;
  height: 32px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ctaWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta {
  border-radius: 12px;
  border: 1px solid $color-orange-400;
  background: #fff;
  display: flex;
  width: 204px;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  color: $color-neutral-theme-700;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  z-index: 3;
  transition: all 0.3s ease-in-out;

  cursor: pointer;

  &:hover {
    background: $color-orange-100;
  }
}