@import '/src/styles/mixin';
@import '/src/styles/color';

.slide {
  border-radius: 18px;

  .coverSingleVideoSrc {
    background-color: $neutral-white;
    @include aspect(796, 425);
    border: 4px solid $color-primary-600;
    overflow: hidden;
    border-radius: 18px;
  }

  .cover {
    background-color: transparent;
    position: relative;

    @include aspect(281, 500);
    height: 65vh;
    perspective: 10000px;

    .cover_content {
      overflow: hidden;
      border-radius: 18px;
      border: 4px solid $color-primary-600;
      position: absolute;
      width: 100%;
      height: 100%;

      transition: transform 1s;
      transform-style: preserve-3d;
      transform: rotateY( 180deg ) ;
    }

    .cover_content.rotate {
      transform: rotate(0);
      transition: transform 0.5s;
    }

    .cover_front,
    .cover_back {
      position: absolute;
      height: 100%;
      width: 100%;
      background: white;
      line-height: 300px;
      color: #03446A;
      text-align: center;
      font-size: 60px;
      border-radius: 5px;
      backface-visibility: hidden;
    }

    .cover_back {
      background: #03446A;
      color: white;
      transform: rotateY( 180deg );
    }
  }
}

.title {
  text-transform: capitalize;
}

.iconBottom {
  display: flex;
  justify-content: space-between;

  .dots {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-left: 64px;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $neutral-200;
      transition: all 0.3s ease-in-out;

      &.active {
        background-color: $color-primary-600;
      }
    }
  }

  .skipBtn {
    border-radius: 12px;
    padding: 8px 16px;
    opacity: 1;

    &.isHidden {
      opacity: 0;
    }
  }
}

.startBtn {
  padding: 16px 32px !important;
  border-radius: 16px;
}