@import '/src/styles/color';
@import '/src/styles/mixin';

.STContainer {
  padding: 0 24px;
  border-top: 1px solid #DADBE7;
  background-color: $color-neutral-theme-50;
}

.containerLightMode {
  padding: 0 8px;
  border-top: 1px solid #DADBE7;
  background-color: $color-neutral-theme-50;
}

.STHeader {
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
}

.STHeaderLightMode {
  outline: 0;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  cursor: pointer;
}

.STitle {
  font-family: "Inter";
  font-weight: 700;
  line-height: 24px;
  font-size: 20px;
  margin: 0;
  color: $color-neutral-theme-900;
}

.STArrowIcon {
  transition: all 0.2s ease-in-out;
  height: 20px;
}

.PoweredBy{
  color: $color-neutral-theme-300;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  margin: 0;
  margin-right: 4px;
}