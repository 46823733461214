@import '/src/styles/mixin';
@import '/src/styles/color';

.container {
  box-shadow: 0px 40px 80px -12px rgba(37, 38, 46, 0.10);
  width: 60vw;
  overflow: hidden;

  @include mediaQueryMax($screenLarge) {
    width: 90vw;
    box-shadow: none;
  }

  border-radius: 18px;
  background-color: $neutral-white;
}

.cover {
  aspect-ratio: 796 / 425;
  border: 4px solid $color-primary-600;
  overflow: hidden;
  border-radius: 18px;
}

.headerText {
  width: 100%;
  top: -76px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}