@import '/src/styles/color';
@import '/src/styles/mixin';

.redeemCodeContainer {
  position: absolute;
  left: 50%;
  top: 76px;
  transform: translate(-50%, -200px);
  z-index: 1000;
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: translate(-50%, 0);
  }
}

.closeRedeemCodeBtn {
  border: 0;
  outline: 0;
  top: -8px;
  right: -8px;
  position: absolute;
  background-color: $neutral-white;
  z-index: 1000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: 0px 8px 16px rgb(37 38 46 / 10%);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
  }
}

.TraitFilterContainer {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.privateHeader {
  position: relative;
  z-index: 999;
  width: 100%;
  padding: 8px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  backdrop-filter: blur(20px);
  background-color: $color-neutral-theme-50 !important;

  @include onlyMobile() {
    padding: 12px !important;
  }
}

.STTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 75%;

  @media (max-width: 1250px) {
    width: 70%;
  }

  @media (max-width: 940px) {
    width: 65%;
  }

  @media (max-width: 800px) {
    width: 60%;
  }

  @include tablet() {
    display: none;
  }
}

.titleWrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.STLeftLogo {
  display: none;

  @include tabletAndMobile() {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-right: 16px;
  }
}

.STRight {
  display: flex;
  align-items: center;
  gap: 16px;

  @include tabletAndMobile() {
    gap: 8px;
  }
}


.RecordTabsContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

}

.STSecondHeader {
  position: relative;
  z-index: 998;
  width: 100%;
  padding: 0 22px 18px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-top: 6px; // + 8px from margin bottom of first row header = 14px
  background-color: $color-neutral-theme-50 !important;

  @include onlyMobile() {
    padding: 0 12px 12px 12px;
  }
}