@import '/src/styles/mixin';
@import '/src/styles/color';

.WorkingLocation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
}

.remote {
  border-radius: 12px;
  box-shadow:0px 0px 0px 1px $color-neutral-theme-200 inset;
  padding: 12px 16px;
}