@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  width: 270px;
  @include aspect(270px, 480px);
  overflow: hidden;
  position: relative;
}

.showHumanSideTarget {
  position: absolute;
  right: 0;
  top: 110px;
  width: 0;
  height: 0;
}

.emptyContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  border-radius: 18px;
  border: 1px solid $color-neutral-theme-200;
  background-color: $color-neutral-theme-100;
  overflow: hidden;
}

.recordZone {
  display: flex;
  width: calc(100% - 48px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 16px;
  flex: 1;

  background-color: $color-neutral-theme-50;
  border-radius: 12px;
  border: 1px dashed $color-neutral-theme-250;
}

.videoContainer {
  height: 100%;
  background-color: black;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.video {
  width: 100%;
  @include aspect(656, 369);
}

.videoButtons {
  position: absolute;
  bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title_required {
  user-select: none;
  &::after {
    content: '*';
    color: $color-negative-500;
  }
}

.mobileSampleContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
