.button {
  border-radius: 12px;
  padding: 8px 16px !important;
  height: auto !important;
  text-transform: unset;

  display: flex;
  flex-direction: row;
  gap: 8px;
}
