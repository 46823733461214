@import 'size';

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transition-property($property...) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -ms-transition-property: $property;
  -o-transition-property: $property;
  transition-property: $property;
}

@mixin transition-duration($duration...) {
  -webkit-transition-property: $duration;
  -moz-transition-property: $duration;
  -ms-transition-property: $duration;
  -o-transition-property: $duration;
  transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
  -webkit-transition-timing-function: $timing;
  -moz-transition-timing-function: $timing;
  -ms-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin box-shadow($box-shadow...) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  -ms-box-shadow: $box-shadow;
  -o-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin box-sizing($box-sizing...) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  -ms-box-sizing: $box-sizing;
  -o-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}

@mixin transform($transform...) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin linear-gradient($linear-gradient...) {
  background: -webkit-gradient($linear-gradient);
  background: -webkit-linear-gradient($linear-gradient);
  background: -moz-linear-gradient($linear-gradient);
  background: -ms-linear-gradient($linear-gradient);
  background: -o-linear-gradient($linear-gradient);
  background: linear-gradient($linear-gradient);
}

@mixin animation($animation...) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin animation-name($animation-name...) {
  -webkit-animation-name: $animation-name;
  -moz-animation-name: $animation-name;
  -ms-animation-name: $animation-name;
  -o-animation-name: $animation-name;
  animation-name: $animation-name;
}

@mixin animation-delay($animation-delay...) {
  -webkit-animation-delay: $animation-delay;
  -moz-animation-delay: $animation-delay;
  -ms-animation-delay: $animation-delay;
  -o-animation-delay: $animation-delay;
  animation-delay: $animation-delay;
}

@mixin animation-duration($animation-duration...) {
  -webkit-animation-duration: $animation-duration;
  -moz-animation-duration: $animation-duration;
  -ms-animation-duration: $animation-duration;
  -o-animation-duration: $animation-duration;
  animation-duration: $animation-duration;
}

@mixin animation-iteration-count($animation-iteration-count...) {
  -webkit-animation-iteration-count: $animation-iteration-count;
  -moz-animation-iteration-count: $animation-iteration-count;
  -ms-animation-iteration-count: $animation-iteration-count;
  -o-animation-iteration-count: $animation-iteration-count;
  animation-iteration-count: $animation-iteration-count;
}

@mixin animation-timing-function($animation-timing-function...) {
  -webkit-animation-timing-function: $animation-timing-function;
  -moz-animation-timing-function: $animation-timing-function;
  -ms-animation-timing-function: $animation-timing-function;
  -o-animation-timing-function: $animation-timing-function;
  animation-timing-function: $animation-timing-function;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

// figma mapping

@mixin elevation1 {
  @include box-shadow(0px 16px 48px -12px rgba(37, 38, 46, 0.1));
}


@mixin mediaQueryMin($screen) {
  @media screen and (min-width: $screen) {
    @content;
  }
}

@mixin mediaQueryMax($screen) {
  @media screen and (max-width: ($screen - 1)) {
    @content;
  }
}

// deprecated
@mixin mobile() {
  @media screen and (max-width: ($screenMedium - 1)) {
    @content;
  }
}

// deprecated
@mixin ipad() {
  @media screen and (max-width: ($screenLarge - 1)) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: $screenMobileL) and (max-width: ($screenTablet)) {
    @content;
  }
}

@mixin onlyMobile() {
  @media screen and (max-width: $screenMobileL) {
    @content;
  }
}


@mixin tabletAndMobile() {
  @media screen and (max-width: ($screenTablet)) {
    @content;
  }
}

@mixin tabletTo1024() {
  @media (max-width: 1024px) and (min-width: ($screenTablet + 1)) {
    @content;
  }
}

@mixin screenSmall() {
  @media (max-width: $screenSmall) {
    @content;
  }
}

@mixin aspect($width: 16, $height: 9) {
  aspect-ratio: $width / $height;

  @supports not (aspect-ratio: $width / $height) {
    &::before {
      content: '';
      float: left;
      padding-top: calc((#{$height} / #{$width}) * 100%);
    }

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  }
}


@mixin avatar($width, $height) {
  font-size: 0;
  text-align: center;
  width: $width;
  height: $height;

  img {
    display: inline-block;
    vertical-align: middle;
    max-height: 100%;
    max-width: 100%;
  }

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}