@import '/src/styles/color';
@import '/src/styles/mixin';

.recorderVideoContainer {
  flex: 1;
  position: relative;
  border-radius: 8px;
  aspect-ratio: 9/16;
  z-index: 1000;

  @include mediaQueryMax(768px) {
    border-radius: 0;
  }
}

.VideoActions {
  z-index: 1000;
  margin-bottom: 24px;
}

.ReplayingActions {
  position: absolute;
  right: -198px;
  bottom: 48px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  display: none;
}

.SubmitBtn {
  height: 40px;
  min-width: 190px;
  border-radius: 12px;
  padding: 8px 48px;
}

.StartOverBtn {
  height: 40px;
  min-width: 190px;
  border-radius: 12px;
  padding: 8px 48px;
  background: $color-white;
  color: $color-neutral-theme-900;
  border: 1px solid $color-neutral-theme-300;
}