@import '/src/styles/mixin';
@import '/src/styles/color';

.STLike {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-cyan-100;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &_withText {
    width: auto;
    padding: 0 16px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: block;
    color: $color-cyan-400;
  }
}

.STSuperLike {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-orange-100;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &_withText {
    width: auto;
    padding: 0 16px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    display: block;
    color: $color-orange-500;
  }
}

.STSuperNova {
  width: 40px;
  height: 40px;
  background: linear-gradient(219.81deg, #ECA0FF 15.34%, #AAB2FF 51.08%, #84FFC9 84.66%);
  border-radius: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  &_withText {
    width: auto;
    padding: 0 16px;
  }

  svg {
    path:nth-of-type(0) {
      fill: unset;
    }
  }

  span {
    display: block;
    color: $color-neutral-theme-50;
  }
}

.STButtonShare {
  height: 40px;
  background: $color-neutral-theme-100;
  border-radius: 39px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  color: $color-neutral-theme-900;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: $color-neutral-theme-200;
  }
}

.STPopper {
  width: 208px;
  height: 80px;
  box-shadow: 0px 8px 16px rgb(37 38 46 / 20%);
  border-radius: 24px;
  background: $color-neutral-theme-50;
  justify-content: center;
  margin-bottom: 5px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}