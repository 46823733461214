@import '/src/styles/color';
@import '/src/styles/mixin';

.STSettingContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.STFormContainer {
  flex: 1;
  background: $color-neutral-theme-50;

  flex: 1;
  border-radius: 16px;
  margin: 12px 16px;
  padding: 24px 0;
  margin-top: 0;

  @include onlyMobile() {
    border-radius: 0px;
    padding: 24px;
    margin: 0;
  }

  display: flex;
  justify-content: center;
}

.STFormContentWrap {
  flex:1;
  max-width: 400px;
}

.uploadContainer {
  .uploadNote {
    padding-top: 8px;
    font-size: 12px;
    font-weight: 400;
  }
}

.customLabel {
  color: $color-neutral-theme-500;
}

.customeSubmitBtn {
  border-color: transparent;
}
