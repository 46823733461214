@import '/src/styles/color';

.container {
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  background-color: $color-purple-200;
}

