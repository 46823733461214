@import '/src/styles/color';
@import '/src/styles/mixin';
@import '/src/styles/_size';

.STContainer {
  position: relative;
  display: grid;
  padding: 32px;
  gap: 16px;
  min-height: 100%;
  width: 100%;
  grid-auto-rows: min-content;

  @include onlyMobile() {
    padding: 12px;
  }

  grid-template-columns: repeat(2, 1fr);

  @media only screen and (min-width: 1380px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1580px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2242px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include mediaQueryMax($screenMedium) {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  @include mediaQueryMax($screenSmall) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.vibes {
  overflow: hidden;
}

.vibesWrapper {
  display: flex;
  flex-direction: column;
  background: $color-neutral-theme-50;
  border-radius: 16px;
  height: 100%;
}

.icons {
  z-index: 100;
  position: absolute;
  left: 50%;
  top: -40px;
  transform: translate(-50%, -100%);

  .icon {
    border-radius: 100%;
    background-color: $color-neutral-theme-50;
  }
}

.videoPlayerWrapper {
  background: black;
  box-sizing: content-box;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.IconWrapper {
  position: relative;
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  height: 100%;
}