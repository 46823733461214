@import '/src/styles/color';

.container {
  display: flex;
  flex-direction: column;
  gap: 29px;
  width: 100%;
  overflow-x: hidden;
}

.top {
  padding: 24px 16px;
}

.topInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  padding: 16px 12px;
  background-color: $color-purple-50;
  border-radius: 16px;
}

.topInfo_heading {
  color: $color-neutral-theme-900;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}


.topInfo_package {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.topInfo_packages {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.topInfo_icon {
  color: $color-purple-400;
  flex-shrink: 0;
}

.plans {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
  padding-bottom: 32px;
}

.cardWidth {
  min-width: 300px;
  max-width: unset;
}

.bottom {
  height: 400px;
}

.swiper {
  height: 100%;
}