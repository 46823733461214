@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @include tabletAndMobile() {
    flex-direction: column;
    height: 133px;
  }
}

.salaryRange {
  height: 56px;
  background-color: $color-neutral-theme-100 !important;
  border-radius: 16px !important;
  justify-content: space-between !important;
  padding: 0 16px;
  flex: 1;
  max-width: 194px;

  @include tabletAndMobile() {
    max-width: unset;
  }
}

.salaryRate {
  height: 56px;
  flex: 1;
  background-color: $color-neutral-theme-100 !important;
  border-radius: 8px !important;
  justify-content: space-between !important;
  padding: 0 16px;
  max-width: 159px;
  font-weight: 400 !important;

  @include tabletAndMobile() {
    max-width: unset;
  }
}

.salaryValueRange {
  height: 56px;
  background-color: $color-neutral-theme-100 !important;
  border-radius: 16px !important;
  ;
  justify-content: space-between !important;
  padding: 0 16px;
  flex: 1;
}

.salaryValueExtra {
  height: 56px;
  background-color: $color-neutral-theme-100 !important;
  border-radius: 8px;
}

.salaryValueCustom {
  height: 56px;
  display: flex;
  flex: 1;
  background-color: $color-neutral-theme-100 !important;
  border-radius: 8px !important;
  justify-content: space-between !important;
  padding: 0 16px;
  align-items: center;
  gap: 12px;

  &:hover {
    box-shadow: 0 0 0 1px $color-neutral-theme-200;
  }

  &.focused{
    box-shadow: 0 0 0 1px $color-neutral-theme-300;
  }

  &.error{
    box-shadow: 0 0 0 1px $color-negative-500;
  }

  @include tabletAndMobile() {
    max-width: unset;
  }

  &_adornment {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: $color-neutral-theme-900;
  }

  &_group {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;

      color: $color-neutral-theme-900;

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-neutral-theme-300;
        opacity: 1;
        /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-neutral-theme-300;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $color-neutral-theme-300;
      }
    }

    span {
      padding-left: 0;
      padding-right: 8px;
    }
  }
}

.menu {
  z-index: 88888888888;
}