@import '/src/styles/color';
@import '/src/styles/mixin';

.RecordBtn {
  outline: none;
  padding: 0;
  border: 0;
  margin: 0;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;

  .RecordBtn {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid $color-primary-800;
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .RecordBtnBackground {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background-color: $color-primary-400;
    }
  }
}