@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  position: relative;
  padding: 32px 48px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;
}

.header_title {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.02em;

  @include onlyMobile() {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.48px;
  }
}

.header_info {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;

  border-radius: 16px;
  background: $color-purple-50;
}

.header_info_title {
  color: $color-neutral-theme-900;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.header_info_packages {
  display: flex;
  flex-direction: row;
  gap: 36px;
  align-items: center;
}

.header_info_package {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.header_info_package_icon {
  color: $color-purple-400;
  flex-shrink: 0;
}

.plans {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 32px;
  align-items: flex-start;
}