@import '/src/styles/color';
@import '/src/styles/mixin';

.STContainer {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: $color-neutral-theme-100 !important;
  cursor: pointer;
  > svg {
    cursor: pointer;
  }
}

.redDotCollapse {
  width: 10px;
  height: 10px;
  background-color: $color-negative-500;
  border-radius: 50%;
  position: absolute;
  top: 9px;
  right: 9px;
}