@include keyframes(ringing) {
  0% { @include transform(rotate(0)); }
  1% { @include transform(rotate(30deg)); }
  3% { @include transform(rotate(-28deg)); }
  5% { @include transform(rotate(34deg)); }
  7% { @include transform(rotate(-32deg)); }
  9% { @include transform(rotate(30deg)); }
  11% { @include transform(rotate(-28deg)); }
  13% { @include transform(rotate(26deg)); }
  15% { @include transform(rotate(-24deg)); }
  17% { @include transform(rotate(22deg)); }
  19% { @include transform(rotate(-20deg)); }
  21% { @include transform(rotate(18deg)); }
  23% { @include transform(rotate(-16deg)); }
  25% { @include transform(rotate(14deg)); }
  27% { @include transform(rotate(-12deg)); }
  29% { @include transform(rotate(10deg)); }
  31% { @include transform(rotate(-8deg)); }
  33% { @include transform(rotate(6deg)); }
  35% { @include transform(rotate(-4deg)); }
  37% { @include transform(rotate(2deg)); }
  39% { @include transform(rotate(-1deg)); }
  41% { @include transform(rotate(1deg)); }
  43% { @include transform(rotate(0)); }
  100% { @include transform(rotate(0)); }
}

.ringing-animation {
  @include animation(ringing 3s .1s ease-in-out infinite);
}
