@import '/src/styles/color';
@import '/src/styles/size';
@import '/src/styles/mixin';

.asideLogo {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.aside {
  width: 260px;
  flex-shrink: 0;
  height: var(--window-height);
  overflow: auto;
  box-sizing: border-box;
  padding: 16px 12px;
  background-color: $color-neutral-theme-900 !important;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.asideMinimized {
  width: 104px !important;
}

$firstLevelPaddingX: 16px;
$secondLevelPaddingX: 32px;

.marketPlaceWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.disable {
  color: $color-neutral-theme-300;

  svg {
    path {
      fill: $color-neutral-theme-300;
    }
  }

  &:hover {
    color: $color-neutral-theme-300 !important;

    svg {
      path {
        fill: $color-neutral-theme-300 !important;
      }
    }
  }
}

.asideTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
}

.redDot {
  width: 10px;
  height: 10px;
  background-color: $color-negative-500;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 4px;
}

.buttonSignIn {
  width: 100% !important;
  padding: 16px !important;
  display: flex;
  flex-direction: row;
  gap: 8px;
}