@import '/src/styles/color';
@import '/src/styles/variables';
@import '/src/styles/mixin';
@import '/src/styles/typography';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  border-radius: 12px;
  background-color: white;
  gap: 40px;

  &:hover {
    box-shadow: 0 0 0 2px $color-neutral-theme-200 inset;
    cursor: pointer;
  }

  min-width: 241px;
}

.containerDraft {
  &:hover {
    box-shadow: none;
    cursor: not-allowed;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.iconMore {
  path {
    fill: $color-neutral-theme-250;
  }

  width: 16px;
  height: 16px;
}

.buttonMore {
  padding: 0;
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 8px;
  border: none;
  transition: none;

  &:hover, &:focus, &:active {
    border: 1px solid $color-neutral-theme-200;
    background-color: transparent !important;
  }
}

.menu {
  [class*='MuiPaper-root'] {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid $color-neutral-theme-200;
  }
}

.menuItem {
  border-radius: 12px;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  padding: 8px;

  &:hover {
    span {
      color: $color-neutral-theme-700;
    }
  }

  svg {
    path {
      fill: $color-neutral-theme-400;
    }

    width: 20px;
    height: 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.chip {
  padding: 4px 8px;
  width: fit-content;
  border-radius: 10px;
  background: $color-neutral-theme-100;
  font-size: 12px;
  font-weight: 600;
  color: $color-neutral-theme-400;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    color: $color-neutral-theme-500;
  }
}

.footer {
  gap: 24px;
}

.avatarGroup {
  [class*='MuiAvatarGroup-avatar'] {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }
}

.popper {
  z-index: 99999 !important;
}

.tooltip {
  background-color: $color-neutral-theme-250 !important;
  padding: 8px !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);

  @extend .body1-bold;
  color: $color-neutral-theme-900 !important;
}

.tooltipArrow {
  &::before {
    background-color: $color-neutral-theme-250 !important;
  }
}

.popperHidden {
  visibility: hidden;
}