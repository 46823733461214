@import '/src/styles/variables';

.container {
  position: relative;
  padding: 14px 16px;
  background-color: $color-neutral-theme-100;
  border-radius: 18px;
}

.editor {
  width: 300px;
  height: 240px;
  resize: none;
  background-color: transparent;

  outline: none;
  border: none;
  color: $color-neutral-theme-700;

  &::placeholder {
    color: $color-neutral-theme-300;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
}

.mobile_editor {
  @extend .editor;
  height: 100%;
  width: 100%;
  flex: 1;
}

.counter {
  position: absolute;
  right: 16px;
  bottom: 14px;
}

.mobile_container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: $color-neutral-theme-50;
  height: 100%;
}

.mobile_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: relative;
  height: 42px;
}

.mobile_header_title {
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.mobile_close {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: $color-neutral-theme-100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_editor_fullheight {
  flex: 1;
}