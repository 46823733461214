@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  background-color: $color-neutral-theme-50;
  border-radius: 32px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 543px;
}

.ErrorMsg {
  padding: 8px 12px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  gap: 4px;

  svg {
    fill: $color-negative-500;
  }
}

.STSignUpEmailHint {
  font-size: 12px !important;
  display: flex;
  align-items: center;
  gap: 10px;
  color: $color-negative-500;

  svg {
    fill: currentColor;
  }
}
