@import '/src/styles/mixin';
@import '/src/styles/color';

// .firstGroup {
//   display: flex;
//   flex-direction: row;
//   gap: 20px;

//   @include tabletAndMobile {
//     width: 100%;
//   }

//   button {
//     min-width: 190px;

//     @include tabletAndMobile {
//       flex: 1;
//       min-width: unset !important;
//     }
//   }
// }

$breakPoint: 745px;

.actionWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px;
  margin-top: auto;
  background-color: $color-neutral-theme-50;
  position: sticky;
  bottom: 0;
  box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);

  @include mediaQueryMax($breakPoint) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }


  // @include tabletAndMobile {
  //   // display: flex;
  //   // flex-direction: column-reverse;

  //   // // button post job
  //   // button:last-child {
  //   //   width: 100%;
  //   //   padding: 15px; // to get 56px height
  //   // }
  // }

  .button {
    min-width: 190px;

    @include tabletAndMobile {
      flex: 1;
    }

    @include mediaQueryMax($breakPoint) {
      min-width: unset;
    }
  }
}

.right {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  @include mediaQueryMax($breakPoint) {
    width: 100%;
  }
}

.switchContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switchLabel {
  @include tabletAndMobile() {
    max-width: 194px;
  }

  @include mediaQueryMax($breakPoint) {
    max-width: unset;
  }
}

.switch {
  @include tabletAndMobile() {
    gap: 12px;
  }
}