@import '/src/styles/color';
@import '/src/styles/mixin';

.STSuccessMessageWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.STForgotMessage {
  width: 475px;
  text-align: center;

  @include mobile() {
    width: 100%;
    font-size: 16px;
    padding: 0 24px;
  }
}

.STContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;

  margin: 0 auto;
  max-width: 560px;

  @include onlyMobile() {
    padding: 24px;
  }
}

.SectionSendMail {
  max-width: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 48px;
  gap: 32px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;
  box-shadow: 0px 16px 48px -12px rgba(37, 38, 46, 0.10);

  @include onlyMobile() {
    width: 100%;
    padding: 24px;
  }
}

.iconEmailLarge {

  @include onlyMobile() {
    width: 71px;
    height: 55px;
  }
}

.mainText {
  font-size: 32px !important;
  font-weight: 700 !important;
  letter-spacing: -0.64px;
  text-transform: capitalize;
}

.subText {
  @include onlyMobile() {
    padding: 0 8px;
  }
}

.Email {
  color: $color-neutral-theme-900;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
}

.resendEmail {
  text-align: center;
  @include onlyMobile() {
    text-align: center;
  }
}

.Container {
  background-color: $color-primary-400;
  display: flex;

  @include onlyMobile() {
    padding-top: 82px;
  }
}

.RightSide {
  min-height: 100vh;
  flex: 3;
  background-color: #fff;
  box-sizing: border-box;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-height: 500px) {
    height: fit-content;
  }
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  display: none;

  @include onlyMobile() {
    display: flex;
  }
}

@include onlyMobile() {
  .Container {
    flex-direction: column;
  }

  .LeftSide {
    display: none;
  }

  .RightSide {
    background-color: $color-primary-400;
    flex: 1;
    border-radius: 32px;
    margin: 16px;
  }
}
