@import '/src/styles/mixin';
@import '/src/styles/color';

.Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 18px;

  .Icons {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 14px;

    .Icon {
      cursor: pointer;
    }
  }

  .Text {
    color: $neutral-300;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  @include mobile() {
    .Text {
      color: $neutral-700;
    }
  }
}