@import '/src/styles/color';
@import '/src/styles/mixin';

.STHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
}

.STTitle {
  font-weight: 700;
  font-size: 24px;
  color: $color-neutral-theme-900;
}