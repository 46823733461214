@import '/src/styles/color';

.trait {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  padding: 4px 8px;
  border-radius: 25px;
  color: $color-neutral-theme-300;
  background-color: $color-neutral-theme-100;
}

// .matchTrait {
//   color: $color-neutral-theme-900;
//   background-color: $color-positive-200;
// }

.noTraitSelected {
  color: $color-neutral-theme-900 !important;
  background-color: $color-neutral-theme-100 !important;
}