@import '/src/styles/color';
@import '/src/styles/mixin';

.hmInfo {
  max-width: 584px;
  background-color: $color-primary-400;
  border-radius: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 130px 75px;
  height: 100%;
  width: 100%;
  flex: 1;

  @include tabletAndMobile() {
    padding: 36px 24px 75px;
  }
}

.hmVideoWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: center;
}

.hmName {
  color: #000;
  text-align: center;
  font-family: Walter Turncoat;
  font-size: 32px;
  font-weight: 400;
  line-height: 117.148%;
  letter-spacing: -0.96px;
  display: block;
}

.hmVideo {
  border-radius: 12px;
  border: 3px solid #0A0A0A;
  box-shadow: -8px 8px 0px 0px #010101;
  width: 324px;
  @include aspect(324, 576);
  overflow: hidden;

  @include onlyMobile() {
    width: 100%;
  }
}

.hmVideoPlayer {
  video {
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.likeIllustration {
  position: absolute;
  top: 44px;
  left: 25px;

  @include mediaQueryMax(700px) {
    display: none;
  }
}

.startIllustration {
  position: absolute;
  bottom: 32px;
  right: 0;

  @include mediaQueryMax(700px) {
    display: none;
  }
}