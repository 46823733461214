@import '/src/styles/color';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 12px 16px;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: $color-neutral-theme-700;
  }
}

.containerMinimize {
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  width: 48px;
}

.arrow {
  transition: all 0.2s ease-in-out;
}

.earnMoneyAnimation {
  bottom: -8px;
}