@import '/src/styles/color';
@import '/src/styles/mixin';

.WatchHome {
  background-color: $color-neutral-theme-700;
  overflow: auto;

  @include mediaQueryMax($screenMediumSmall) {
    height: 100vh;
  }

  .Vibe {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: auto;
    gap: 16px;
    margin-bottom: 16px;
    background: $color-black;

    @include mediaQueryMax($screenMediumSmall) {
      width: 100% !important;
      height: auto !important;
      position: relative;

      .CommentBtn {
        position: absolute;
        bottom: 64px;
        right: 16px;
        outline: 0;
        border: 0;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: $color-neutral-theme-700;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
      }
    }
  }
}

:export {
  neutral500: $color-neutral-theme-500;
  neutralWhite: $color-white;
  width32: 32px;
}