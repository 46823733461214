@import '/src/styles/color';
@import '/src/styles/mixin';

.editor {
  display: flex;
  flex-direction: column;
  background-color: #F1F2F3;

  > [class*="ql-toolbar"] {
    // border-top-right-radius: 12px;
    // border-top-left-radius: 12px;
    border-left: none;
    border-right: none;
    background-color: $color-neutral-theme-50;
    border-color: $color-neutral-theme-200 !important;
  }

  > [class*="ql-container"] {
    flex: 1;
    // border-bottom-right-radius: 12px;
    // border-bottom-left-radius: 12px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: unset;
    min-height: 135px;
  }

  [class*="ql-editor"] {
    font-size: 16px;
    color: $color-neutral-theme-900;
    max-height: 640px;
    min-height: 134px;
  }

  [class*="ql-editor"] ol, ul {
    padding-left: 0px;
  }

  [class*="ql-editor ql-blank"]::before{
    font-style: normal;
  }
}
