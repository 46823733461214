@import 'color';
@import 'mixin';

.RecordTabs {
  min-height: auto !important;

  .MuiButtonBase-root {
    text-transform: capitalize;
    opacity: 0.7;
    padding: 0 !important;
    min-height: auto;
    min-width: auto;
    height: 32px;
    width: 75px;
    font-size: 16px;
    font-weight: 500;

    @include mediaQueryMax(769px) {
      color: $color-white !important;
      opacity: 0.5;
    }
  }

  .Mui-selected {
    background-color: transparent;
    color: $color-neutral-theme-900 !important;
    opacity: 1;

    @include mediaQueryMax(769px) {
      color: $color-white !important;
    }
  }

  .MuiTabs-indicator {
    background-color: $color-cyan-500;
    height: 4px;
    border-radius: 2px;
    width: 27px !important;
    margin: 0 24px !important;
    margin-bottom: 4px;
  }

  @include mediaQueryMax(769px) {
    margin-bottom: 8px;
  }
}

.SampleCarousel {
  .swiper-slide-prev {
    transform: translate(0, -7.5%) scale(0.5) !important;
  }

  .swiper-slide-next {
    transform: translate(0, -7.5%) scale(0.5) !important;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
}

.customSwiperTutorial {
  .swiper-slide {
    height: auto;
  }
}