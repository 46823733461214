@import '/src/styles/color';
@import '/src/styles/mixin';

.STContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: auto;
  border-radius: 32px;
  padding: 32px 48px;
  background-color: $color-neutral-theme-50;

  @include mobile() {
    width: 100%;
    padding: 48px 24px;
  }
}

.STHeader {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__Title {
      font-size: 40px;
      font-weight: 700;
      color: $color-neutral-theme-700;
      font-style: normal;
      line-height: 48px;
      text-align: center;
      letter-spacing: -0.02em;
    }

    &__Description {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $color-neutral-theme-300;
    }
}

.STBody {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

.STForm {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 393px;

  @include mobile() {
    width: 339px;
    min-width: unset;
  }
}

.STImage {
  @include tabletAndMobile() {
    display: none;
  }
}
