@import '/src/styles/color';
@import '/src/styles/mixin';

.header {
  padding: 8px 22px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  height: 68px;
}

.buttonCreateCampaign {
  height: 44px;
  border-radius: 12px;
  padding: 8px 16px;
  text-transform: unset;
}

.STRight {
  display: flex;
  align-items: center;
  gap: 16px;

  @include tabletAndMobile() {
    gap: 8px;
  }
}

.STIconButton {
  background-color: $color-neutral-theme-100 !important;
  width: 44px;
  height: 44px;
}

.titleWrapper {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.subTitleWrapper {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  color: $color-neutral-theme-250;

  &:hover {
    color: $color-neutral-theme-700;
  }

  cursor: pointer;

  @include tabletAndMobile() {
    display: none;
  }

  &.lastSubTitleWrapper {
    color: $color-neutral-theme-700;

    &:hover {
      color: $color-neutral-theme-250;
    }
  }
}

.STLeftLogo {
  display: none;

  @include tabletAndMobile() {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-right: 16px;
  }
}