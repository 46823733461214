@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  position: relative;
  width: 100%;

  &.error {
    border: 1px solid $color-negative-500;
    border-radius: 8px;
  }
}

.inputSearch {
  padding: 14px 40px 14px 16px;
  background-color: $color-neutral-theme-100;
  color: $color-neutral-theme-700;
  border-radius: 8px;
  outline: none;
  border: none;
  height: 56px;
  width: 100%;

  &::placeholder {
    color: $color-neutral-theme-300;
  }
}

.iconSearch {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
}