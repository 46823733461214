.container {
  display: flex;
  flex-direction: column;
  background: var(--color-neutral-theme-50);
}

.header {
  display: flex;
  padding: 14px 16px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-neutral-theme-100);
}

.headerTitle {
  font-size: 18px;
  font-weight: 700;
  color: var(--color-neutral-theme-700);
  text-transform: capitalize;
}
