@import '/src/styles/color';

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  background-color: rgba(24, 25, 32, 0.70);
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  gap: 6px;
}

.containerOutsideViewport {
  position: fixed;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  border-radius: 10px;
  padding: 8px 16px;
  background-color: $color-neutral-theme-700;
}