@import '/src/styles/color';
@import '/src/styles/mixin';

.STContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 34px;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    h5,
    span {
      max-width: 498px;
      text-align: center;
    }

    .vibeBtn {
      margin-top: 12px;
      padding: 16px 32px;
    }
  }


  .imgContainer {
    max-width: 50vw;
    overflow: hidden;

    @include mediaQueryMax($screenTablet) {
      max-width: 70vw;
    }

    @include mediaQueryMax($screenMediumSmall) {
      max-width: 90vw;
    }

    border-radius: 18px;
    background-color: $neutral-white;
  }

  .cover {
    border: 4px solid $neutral-250;
    background-color: $neutral-white;
    aspect-ratio: 796 / 425;
    overflow: hidden;
    border-radius: 18px;
  }
}