@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 32px;
  border-radius: 16px;

  @include onlyMobile() {
    margin: 0;
    border-radius: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  align-items: center;
  flex: 1;
  gap: 32px;
  width: 100%;
  padding: 32px 24px;
}

.table {
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 32px 24px;

  button {
    width: 201px;
    height: 56px;
  }

  @include onlyMobile() {
    border-top: 1px solid $color-neutral-theme-100;
  }
}

.BoxMoblieContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.BoxMoblieWrapper {
  border: 1px solid $color-neutral-theme-200;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
}

.record {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  &_text {
    flex: 1;
    color: $color-neutral-theme-900;
    font-weight: 400;
    font-size: 16px;
  }

  &_checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;

    span {
      margin-right: 0 !important;
    }

    label {
      margin-right: 0 !important;
    }
  }
}