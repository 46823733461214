@import '/src/styles/mixin';
@import '/src/styles/color';

.STSetPasswordWrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;

  margin: 0 auto;
  max-width: 560px;

  @include onlyMobile() {
    padding: 24px;
  }
}

.STForm {
  width: 504px;
  display: flex;
  flex-direction: column;

  padding: 48px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;
  box-shadow: 0px 16px 48px -12px rgba(37, 38, 46, 0.10);


  .Icon {
    align-self: center;
  }

  .LoginHeader {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 32px 0;

    .Title {
      color: $neutral-700;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -0.64px;
      text-transform: capitalize;
    }
  }

  .Box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 408px;

    .Label {
      color: $neutral-500;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .ErrorMsg {
    padding: 8px 12px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    gap: 4px;

    svg {
      fill: $color-negative-500;
    }
  }

  @include onlyMobile() {
    width: 100%;
    padding: 24px;
  }
}

.Container {
  background-color: $color-primary-400;
  display: flex;

  @include onlyMobile() {
    padding-top: 82px;
  }
}

.RightSide {
  min-height: 100vh;
  flex: 3;
  background-color: #fff;
  box-sizing: border-box;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-height: 720px) {
    height: fit-content;
  }
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  display: none;

  @include onlyMobile() {
    display: flex;
  }
}

@include onlyMobile() {
  .Container {
    flex-direction: column;
  }

  .LeftSide {
    display: none;
  }

  .RightSide {
    background-color: $color-primary-400;
    flex: 1;
    border-radius: 32px;
    margin: 16px;
  }
}