@import '/src/styles/color';
@import '/src/styles/mixin';

.Container {
  width: calc(100vw - 48px);
  height: calc(100vh - 48px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CarouselContainer {
  overflow: hidden;
  width: 100%;
  height: calc(100% - 32px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.VideoPlayerCtn {
  border-radius: 8px;
  position: relative;
}

.VidePlayerSlide {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  transition: all 1s;
  z-index: 1;
}

.VidePlayerSlideNext {
  top: 0;
  left: 0;
  transform: translate(125%, 0) scale(0.7);
  pointer-events: none;
}

.NextBtn {
  position: absolute;
  outline: none;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  top: 50%;
  left: 100%;
  transform: translate(16px, -50%);
  transition: opacity 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  svg {
    color: $color-white;
    transform: scale(1.33)
  }

  @include mediaQueryMax(521px) {
    transform: translate(-8px, -50%);
    z-index: 2;
  }
}

.VidePlayerSlidePrev {
  top: 0;
  left: 0;
  transform: translate(-125%, 0) scale(0.7);
  pointer-events: none;
}

.PrevBtn {
  outline: none;
  border: 0;
  background-color: transparent;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translate(-16px, -50%);
  transition: opacity 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;

  svg {
    color: $color-white;
    transform: scale(1.33)
  }

  @include mediaQueryMax(521px) {
    transform: translate(8px, -50%);
    z-index: 2;
  }
}

.NoShow {
  visibility: collapse;
  opacity: 0;
}

.Overlay {
  background-color: rgba(24, 25, 32, 0.85);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.swiperContainer {
  width: 100%;
  height: 100%;
  padding: 24px;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(20px);
  position: relative;
}

.close {
  position: absolute !important;
  right: 8px !important;
  top: 8px !important;
  background-color: white !important;
  z-index: 999999 !important;
}

.swiper {
  height: 100%;
}

.swiperSlider {
  width: 100%;
  height: 100%;
}

.playerWrapper {
  width: 100%;
  height: 100%;
  aspect-ratio: 418 / 744;
  position: relative;
}

.player {
  width: 100%;
  height: 100%;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 auto;
}

.sliderInActive {
  width: 80%;
  transform-origin: center;
}

.centerSwiperSlider {
  margin: auto;
}

.videoPlayerOverride {
  :global {
    video::-webkit-media-text-track-display {
      padding: 0 16px !important;
    }
  }
}