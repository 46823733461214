@import '/src/styles/color';
@import '/src/styles/mixin';

.StartOver {
  background: $color-white;
  padding: 32px 24px;
  border-radius: 32px;
  width: 287px;
  text-align: center;

  .Title {
    font-size: 32px;
    font-weight: 700;
    color: $color-neutral-theme-700;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .Label {
    font-size: 16px;
    line-height: 28px;
    color: $color-neutral-theme-300;
    margin-bottom: 40px;
    margin-top: 0;
  }

  .BtnActions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .StartOverBtn {
    width: 100%;
    height: 40px;
    border: 1px solid $color-negative-500;
    color: $color-negative-500 !important;
    border-radius: 12px;
  }

  .SecondaryBtn {
    width: 100%;
    height: 40px;
    border-radius: 12px;
    border: 1px solid $color-neutral-theme-200;
  }
}