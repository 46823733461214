@import '/src/styles/color';
@import '/src/styles/mixin';


.container {
  z-index: 2;
  position: absolute;
  top: 96px;
  left: calc(100% + 24px);

  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 347px;
}

.containerMobile {
  bottom: 50px;
  top: unset;
  left: 16px;
  max-width: unset;
  width: calc(100% - 32px);
  background: rgba(0, 0, 0, 0.50);
  padding: 24px;
  border-radius: 16px;
  z-index: 1002;
}

.colorWhite {
  color: #fff !important;
}

.colorNeutral200 {
  color: $color-neutral-theme-200 !important;
}

.thumbnailPickerButtonGroups {
  display: flex;
  flex-direction: row;
  gap: 24px;

  @include mediaQueryMax(426px) {
    flex-direction: column;
    gap: 16px;
  }
}

.thumbnailSlider {
  height: 8px;
  color: $color-positive-500 !important;

  [class*='MuiSlider-rail'] {
    background-color: $color-neutral-theme-200 !important;
    opacity: 1 !important;
  }

  [class*='MuiSlider-thumb'] {
    background-color: $color-positive-600 !important;
  }

  [class*='MuiSlider-thumb'] {
    &[class*='Mui-active'] {
      box-shadow: none !important;
    }
  }

  [class*='MuiSlider-valueLabel'] {
    background-color: $color-neutral-theme-600 !important;
    padding: 4px;
    border-radius: 8px;
  }
}

.thumbnailPreview {
  width: 61px;
  height: 110px;
  border-radius: 6px;
  outline: 2px solid $color-neutral-theme-900;
  flex-shrink: 0;
  background-size: cover;
  overflow: hidden;
}

.flip {
  transform: scaleX(-1);
}

.buttonStartOver {
  height: 56px;
}