@import '/src/styles/color';

.container {
  padding: 14px;
  border: 1px solid $color-neutral-theme-100;
  border-radius: 12px;

  transition: height 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
}

.chevronDown {
  transition: transform 0.3s ease-in-out;
}

.upsideDown {
  transform: rotate(180deg);
}

.height0 {
  height: 0;
}

.body {
  overflow: auto;
  display: flex;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
  gap: 8px;
}