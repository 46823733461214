@import '/src/styles/mixin';

.containerGuest {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 44px;
  padding: 32px 60px;
  height: 100%;
  overflow: auto;

  @include onlyMobile() {
    gap: 16px;
    padding: 12px 24px;
  }
}

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.header_tab {
  display: flex;
  flex-direction: row;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @include mediaQueryMax(1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include mediaQueryMax(1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mediaQueryMax(1153px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mediaQueryMax(842px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mediaQueryMax(610px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.empty {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}