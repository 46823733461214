@import '/src/styles/color';

.container {
  display: flex;
  max-width: 300px;
  flex: 1;
  padding: 60px 16px 32px 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 24px;
  position: relative;

  box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);
}

.containerSingle {
  gap: 24px;
  background-color: $color-neutral-theme-50;
}

.containerRegular {
  gap: 32px;
  background-color: $color-primary-400;
}

.containerSaving {
  gap: 72px;
  background-color: $color-neutral-theme-50;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.header {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.header_info {
  display: flex;
  flex-direction: column;
}

.header_name {
  color: $color-neutral-theme-300;
  display: block;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.header_number_credit {
  color: $color-neutral-theme-900;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.pricingWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pricing {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.pricing_price {
  color: $color-neutral-theme-900;

  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 39px;
  letter-spacing: -0.64px;
}

.pricing_info {
  color: $color-neutral-theme-300;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  gap: 4px;
}

.pricing_credit {
  color: $color-neutral-theme-800;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.badge {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;

  position: absolute;
  right: 12px;
  top: 12px;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.badge_primary {
  background: $color-primary-600;
  color: $color-primary-900;
}

.badge_purple {
  background: $color-purple-100;
  color: $color-purple-400;
}