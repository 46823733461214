@import '/src/styles/color';

.container {
  border-radius: 8px;
  width: 100%;
  background-color: $color-cyan-100;
  padding: 8px 14px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;

  align-items: center;
}
