@import '/src/styles/color';
@import '/src/styles/mixin';

.actions {
  position: absolute;
  top: 64px;
  left: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  @include transform(translateX(24px));
  z-index: 1001;

  @include tabletAndMobile() {
    top: unset;
    left: unset;
    bottom: 172px;
    right: 56px;
  }
}

.actionItemUploadVideo {
  position: absolute;
  bottom: 16px;
  left: 24px;
  z-index: 1001;

  p {
    color: #fff !important;
  }
}

.actionItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 93px;

  button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $color-neutral-theme-200;
    outline: none;
    border: none;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &.UploadImageBtn {
      svg {
        color: $color-neutral-theme-700;

        @include mediaQueryMax(769px) {
          color: $color-white;
        }
      }
    }

    &.UploadVideoBtn {
      background-color: transparent;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: $color-neutral-theme-250;
    }

    &.Active {
      background-color: $color-primary-400;

      svg {
        color: $color-neutral-theme-700;
      }

      &:hover {
        background-color: $color-primary-300;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }

    @include tabletAndMobile() {
      background-color: rgba(24, 25, 32, 0.70) !important;
      path {
        fill: $color-neutral-theme-50;
      }
    }
  }

  p {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    margin: 0;
    line-height: 24px;

    @include tabletAndMobile() {
      color: $color-neutral-theme-50;
    }
  }

  &.Active {
    button {
      background-color: $color-neutral-theme-700;

      svg {
        color: $color-white;
      }
    }
  }

  @include mediaQueryMax(769px) {
    button {
      background-color: rgba(24, 25, 32, 0.5);

      svg {
        color: $color-white;
      }
    }

    p {
      color: $color-white;
    }
  }
}