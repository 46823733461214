@import '/src/styles/color';
@import '/src/styles/mixin';
@import '/src/styles/size';

.STContainerWrapper {
  position: relative;
  flex: 1;
  margin: 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mobile() {
    margin: 24px;
  }

  @include onlyMobile() {
    margin: 12px;
  }

  &.showDetail {
    overflow: hidden;
    margin: 0 !important;

    @media screen and (max-width: 1275px) {
      overflow: auto;
    }

    @media screen and (max-height: 862px) {
      overflow: auto;
    }
  }
}

.STContainer {
  position: relative;
  display: grid;
  gap: 16px;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(2, 1fr);

  @media only screen and (max-width: 1410px) {
    gap: 8px;
  }

  @media only screen and (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 1380px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1580px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (min-width: 1875px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media only screen and (min-width: 2242px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (max-width: 900px) {
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.STLikesItem {
  position: relative;
  border-radius: 16px;
  background: $color-neutral-theme-50;
  background-blend-mode: screen, difference, normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  isolation: isolate;
}

.STButtonIntro {
  border-radius: 12px;
  height: 40px;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.STNewButton {
  position: absolute;
  color: $color-white !important;
  background-color: $color-negative-400!important;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 100;
  font-size: 14px;
  font-weight: 700;
  padding: 4px;
}

.STBottom {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 12px;
  border-top: 1px solid $color-neutral-theme-200;

  @media (max-width: 900px) {
    height: 56px;
    padding: 8px;
  }
}

.STFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 100%;
}

.STFilterLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: $color-neutral-theme-300;
}

.STVideoContainer {
  position: relative;
}

.STVideoWrapper {
  position: relative;
  z-index: 0;
}

// .videoContainerPaddingBottom {
//   padding-bottom: $videoContainerPaddingBottom
// }

.STVideo {
  position: relative;
  width: 100%;
  @include aspect(343, 601);
  background-color: black;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
