@import '/src/styles/color';

.iconPostToKnowMe {
  cursor: pointer;
  margin-left: 6px;
  flex-shrink: 0;

  &:hover {
    path {
      fill: $color-neutral-theme-900;
    }
  }
}
