@import '/src/styles/color';
@import '/src/styles/mixin';

.emptyVideoContainer {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed $color-neutral-theme-250;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @include aspect(270, 480);
  max-width: 270px;
  padding: 21px 18px;
  gap: 21px;
}

.emptyVideoContainer_Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 57px;
}

.promotionBanner {
  width: 100%;
}

.vvc_wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 270px;
  width: 270px;
}

.vvc_videoWrapper {
  background-color: black;
  border-radius: 16px;
  overflow: hidden;
  @include aspect(270, 480);
  width: 100%;
}

.vvc_video {
  width: 100%;
  @include aspect(656, 369);
}

.vvc_buttonSecondary {
  height: 40px;
  border-radius: 12px;

  svg {
    color: $color-neutral-theme-900;
  }
}

.vvc_bottomButtons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 8px;
  }
}
