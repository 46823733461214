@import '/src/styles/color';
@import '/src/styles/mixin';

.introRecordingScreen {
  flex: 1;
  display: flex;
  overflow: hidden;
  margin: 12px;
  align-items: center;
  flex-direction: column;

  @include mediaQueryMax(769px) {
    margin: 0;
  }
}

.recorderContainer {
  height: 100%;
  position: relative;
  aspect-ratio: 9/16;

  @include mediaQueryMax(769px) {
    width: 100%;
  }
}

.recorded {
  height: 100%;
}

.recorderMain {
  display: flex;
  flex-direction: column;

  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  @include mediaQueryMax(769px) {
    border-radius: 0;
  }
}

.floatOnVideo {
  width: 100%;
  position: absolute;
  background-color: rgba(24, 25, 32, 0.7);
  z-index: 1001;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.recordedActions {
  display: flex;
  flex-direction: row;
  padding: 8px 0 12px 0;
  gap: 8px;

  @include mediaQueryMax(769px) {
    padding: 8px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.recordedBtn {
  flex: 1 1 40px;
  height: 40px;
  border-radius: 12px;
}

.btn2nd {
  background: $color-white;
  color: $color-neutral-theme-900 !important;
  border: 1px solid $color-neutral-theme-300;
}

.zIndex1001 {
  z-index: 1001 !important;
}

.buttonStartOver {
  position: absolute;
  bottom: 32px;
  z-index: 1002;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px 32px;
}