@import 'src/styles/color';
@import 'src/styles/mixin';

.container {
  background-color: $color-neutral-theme-50;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  align-items: center;
  border-radius: 24px;
  max-width: 509px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.48px;
  color: $color-neutral-theme-700;
}

.content {
  color: #80818E;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.jobLinkSection {
  display: flex;
  padding: 8px 16px;
  flex: 1;
  gap: 10px;
  justify-content: center;

  border-radius: 8px;
  border: 1px dashed $color-neutral-theme-200;
  max-width: 227px;

  @include mediaQueryMax(490px) {
    max-width: unset;
    width: 100%;
  }
}

.bottom {
  @include mediaQueryMax(490px) {
    flex-direction: column !important;
  }
}