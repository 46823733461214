@import '/src/styles/color';
@import '/src/styles/mixin';

.title {
  font-size: 14px;
  font-weight: 700;
  color: $color-neutral-theme-900;
  line-height: 24px;
  margin: 0;
}

.label {
  font-size: 14px;
  font-weight: 400;
  color: $color-neutral-theme-900;
  line-height: 24px;
  margin: 0;

  span {
    font-weight: 700;
  }
}