@import '/src/styles/mixin';
@import '/src/styles/color';

.STContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;
  max-width: 490px;
  margin-bottom: 32px;

  @include mobile() {
    padding: 24px;
  }
}

.STForm {
  width: 490px;
  display: flex;
  flex-direction: column;

  padding: 56px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;
  box-shadow: 0px 16px 48px -12px rgb(37 38 46 / 10%);

  @include mobile() {
    width: 100%;
    padding: 24px;
  }
}
