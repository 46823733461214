.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 12px;
  position: relative;
  border-radius: 8px;
  position: fixed;
  z-index: 999999;
}

.position {
  position: absolute;
}

.position-left {
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
}

.position-right {
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
}

.position-top {
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.position-bottom {
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.arrow-left {
  transform: rotate(180deg);
}

.arrow-right {
  transform: rotate(0deg);
}

.arrow-top {
  transform: rotate(270deg);
}

.arrow-bottom {
  transform: rotate(90deg);
}