@import '/src/styles/color';
@import '/src/styles/mixin';

.STContainer {
  position: relative;
  display: flex;
  gap: 20px;
  padding: 0 32px 24px;
  height: 100%;

  @include onlyMobile() {
    padding: 0;
  }
}

.STProfileBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  background: $color-neutral-theme-50;
  border-radius: 16px;

  @include onlyMobile() {
    border-radius: 0;
  }

  @include mediaQueryMax(347px) {
    padding: 16px 16px;
  }
}

.STProfileBox_Right {
  max-width: 382px;
  padding: 24px;
  height: fit-content;
}

.STBlockInfo {
  border-radius: 8px;
  margin-top: 16px;
  background: $color-white;
  display: flex;
  flex-direction: column;
}

.STVideo {
  position: relative;
  aspect-ratio: 369/656;
  width: 100%;
  height: 100%;
}

.STEmptyVideo {
  width: 100%;
  box-sizing: border-box;
  height: 470px;
  border: 1px dashed $color-neutral-theme-250;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.top {
  display: flex;
  flex-direction: row-reverse;
  gap: 40px;
  margin-bottom: 16px;

  @include tabletAndMobile() {
    flex-direction: column;
    gap: 16px;
  }
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: $color-neutral-theme-500;
}

.companyWebsites {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_buttonMore {
    width: 103px;
    height: 32px;
  }

  &_buttonRemoveWebsite {
    margin-top: 11.5px !important;
    position: absolute !important;
    right: -34px;

    @include tabletAndMobile() {
      right: 0;
    }
  }

  &_inputWrapper {
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 8px;
    align-items: start;
    width: 100%;

    @include tabletAndMobile() {
      input {
        padding-right: 32px !important;
      }
    }

    svg {
      color: $color-neutral-theme-300;
    }
  }
}

.customLabel {
  color: $color-neutral-theme-500;
}

.customeSubmitBtn {
  border-color: transparent;
}