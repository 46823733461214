.layoutContainer {
  flex: 1 1 auto;
  display: flex;
  width: 100vw;
  overflow: hidden;
}

.layoutContent {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.layoutChildren {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: var(--color-neutral-theme-100);
}

.tourPopup {
  position: fixed;
  bottom: 32px;
  right: 32px;
}