@import '/src/styles/color';
@import '/src/styles/mixin';

.STNoti {
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: $color-neutral-theme-100;
  cursor: pointer;

  svg {
    cursor: pointer;
  }

  &_inverse {
    background-color: unset;

    svg {
      color: white !important;
    }
  }
}

.STDropdown {
  position: absolute;
  z-index: 2;
  top: 65px;
  right: 0;
  width: 371px;
  padding: 24px 16px;
  box-shadow: 0px 40px 80px -12px rgb(37 38 46 / 10%);
  border-radius: 24px;
  background-color: $color-neutral-theme-50 !important;

  &:after {
    content: '';
    display: none;
    width: 100%;
    height: 38px;
    position: absolute;
    left: 0;
    bottom: 16px;
    border-radius: 8px;
    background: linear-gradient(360deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
  }

  &_largeDataSize {
    &:after {
      display: block;
    }
  }

  @include tabletAndMobile() {
    z-index: 1201; // 1200 is z-index of the drawer
    position: fixed;
  }

  @include tablet() {
    left: 232px;
    top: 86px;
  }

  @include onlyMobile() {
    left: 0px;
    top: 86px;
    width: 100%;
  }
}

.STDropdownItemWrapped {
  max-height: calc(100vh - 400px);

  @include tabletAndMobile() {
    max-height: 100vh;
  }

  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.STDropdownItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;

  border-radius: 16px;

  width: calc(100% - 8px);

  background-color: $color-neutral-theme-50 !important;

  &_isSupernova {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &:hover {
    background-color: $color-neutral-theme-100 !important;
  }
}

.STBackgroundSupernova {
  position: absolute;
  background: radial-gradient(95.11% 95.11% at 36.64% 4.89%, #2AD0CA 0%, #E1F664 22.92%, #FEB0FE 46.88%, #ABB3FC 68.23%, #5DF7A4 87.5%, #58C4F6 100%);
  content: '';
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
  border-radius: 16px;
}

.STInfo {
  display: flex;
  align-items: self-start;
  gap: 16px;

  div > span:nth-of-type(1) {
    color: $color-neutral-theme-700;
  }

  &_isSeen {
    div > span:nth-of-type(1) {
      color: $color-neutral-theme-300;
    }
  }

  p {
    margin: 0;
  }
}

.STAvatar {
  position: relative;

  svg {
    position: absolute;
    z-index: 2;
  }
}

.STMultiAvatar {
  position: relative;
  height: 56px;
  width: 56px;
  flex-shrink: 0;

  .supernova {
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
  }

  .MuiAvatar-root {
    position: absolute;
  }

  .bgAvatar1 {
    content: '';
    position: absolute;
    width: 42px;
    height: 42px;
    left: -1px;
    top: -1px;
    border-radius: 50%;
    z-index: 1;
    background: radial-gradient(95.11% 95.11% at 36.64% 4.89%, #2AD0CA 0%, #E1F664 22.92%, #FEB0FE 46.88%, #ABB3FC 68.23%, #5DF7A4 87.5%, #58C4F6 100%);
  }

  .bgAvatar2 {
    content: '';
    position: absolute;
    width: 42px;
    height: 42px;
    left: 15px;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    background: radial-gradient(95.11% 95.11% at 36.64% 4.89%, #2AD0CA 0%, #E1F664 22.92%, #FEB0FE 46.88%, #ABB3FC 68.23%, #5DF7A4 87.5%, #58C4F6 100%);
  }

  .avatar1 {
    left: 0;
    top: 0;
    z-index: 2;
    position: absolute;
  }

  .avatar2 {
    left: 16px;
    top: 16px;
    z-index: 3;
    position: absolute;
  }
}

.STStatus {
  display: none;
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-negative-500 !important;

  &_isShow {
    display: block;
  }
}

.STLoading {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 24px !important;
    height: 24px !important;
  }
}

.STEmpty {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: $color-neutral-theme-300 !important;
  }
}

.STIconButton {
  &_activeAndNotInverse {
    background-color: $color-neutral-theme-100 !important;
  }

  [class*="BaseBadge-badge"] {
    position: absolute !important;
    left: 2px;
    top: -16px;
    height: 16px !important;
    width: fit-content;
    min-width: 16px !important;
    padding: 0 4px !important;
  }

  width: 44px;
  height: 44px;
}
