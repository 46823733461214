@import '/src/styles/color';

.STVideoAuthor {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100% !important;
}

.periodBox {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background-color: $color-neutral-theme-500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 64px;
}

.speedBox {
  cursor: pointer;
  width: unset !important;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  background-color: rgba(24, 25, 32, 0.70);
  position: absolute;
  left: 4px;
  transform: translateX(4px);
  bottom: 64px;
}