@import '/src/styles/mixin';
@import '/src/styles/color';

.STContainer {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;

  margin: 0 auto;
  max-width: 560px;

  @include onlyMobile() {
    padding: 24px;
  }
}

.STContainerOnlyForm {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile() {
    padding: 0 24px;
  }
}

.STFormOnlyForm {
  width: 490px;
  padding: 56px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;
  @include elevation1;

  @include onlyMobile() {
    width: 100%;
    max-width: 490px;
    padding: 24px;
  }
}

.STForm {
  max-width: 560px;
  display: flex;
  flex-direction: column;

  padding: 48px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;
  box-shadow: 0px 16px 48px -12px rgba(37, 38, 46, 0.10);


  .LoginHeader {
    text-align: center;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .Title {
      color: $neutral-700;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -0.64px;
      text-transform: capitalize;
    }

    .Description {
      color: $neutral-400;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .Box {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    @include onlyMobile() {
      flex-direction: column;
    }
  }

  .GuideSection {
    text-align: center;

    span {
      font-size: 14px;
    }

    .Link {
      color: $color-neutral-theme-900;
      font-weight: 500;
      line-height: 150%;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .FormInput {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 32px;

    .Label {
      color: $neutral-500;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .ErrorMsg {
    padding: 8px 12px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    gap: 4px;

    svg {
      fill: $color-negative-500;
    }
  }

  @include onlyMobile() {
    width: 100%;
    padding: 24px;
  }
}

.iconInfo {
  flex-shrink: 0;
}

.STSignUpSubMessage {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
  display: none;

  @include tablet() {
    display: block;
    max-width: 490px;
  }
}

.STSignUpEmailHint {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;

  svg {
    fill: currentColor;
  }
}

.STSignUpEmailHintNotError {
  color: $color-positive-500;
}

.STSignUpEmailHintErrorWithEmail {
  color: $color-negative-500;
}

.STSignUpEmailHintDefault {
  color: $color-neutral-theme-300;
}

.checkbox {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  margin-top: 40px;

  .MuiFormControlLabel-root {
    margin-right: 0 !important;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;

    color: $color-neutral-theme-900;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: $color-cyan-700;

    position: relative;
  }
}

.Container {
  background-color: $color-primary-400;
  display: flex;

  @include onlyMobile() {
    padding-top: 82px;
  }
}

.RightSide {
  min-height: 100vh;
  flex: 3;
  background-color: #fff;
  box-sizing: border-box;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-height: 720px) {
    height: fit-content;
  }
}

.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  display: none;

  @include onlyMobile() {
    display: flex;
  }
}

@include onlyMobile() {
  .Container {
    flex-direction: column;
  }

  .LeftSide {
    display: none;
  }

  .RightSide {
    background-color: $color-primary-400;
    flex: 1;
    border-radius: 32px;
    margin: 16px;
  }
}