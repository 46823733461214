@import '/src/styles/mixin';
@import '/src/styles/color';
@import url('https://fonts.googleapis.com/css?family=Walter+Turncoat&display=swap');


.LeftSide {
  flex: 2;
  background-color: $color-primary-400;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

.logo {
  min-height: 74px;
}

.ImageSection {
  flex: 1;
  position: absolute;
  overflow: hidden;
  visibility: hidden;

  // @media screen and (max-height: 800px) {
  //   bottom: -120px;
  // }
}

.Img {
  width: 100%;
  height: 600px;
}

.typographyContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  max-width: 368px;
  margin-top: 48px;
}

@include onlyMobile() {
  .LeftSide {
    display: none;
  }
}

.headTitle {
  text-transform: capitalize;
  margin: 0;
}

.subTitle {
  padding-left: 10%;
  padding-right: 10%;
}