@import '/src/styles/mixin';
@import '/src/styles/color';

.STContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.STWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;

  align-items: center;
  justify-content: center;
  background-color: $color-neutral-theme-50;

  box-shadow: 0px 16px 48px -12px rgba(37, 38, 46, 0.1);
  border-radius: 32px;

  @include transition(all 0.3s ease-in-out);

  padding: 115px 56px;
  @include mobile() {
    padding: 67px 8px;
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0)
    }
    100% {
      opacity: 1;
      transform: scale(1)
    }
  }

  img {
    animation: show 1s;
  }

  @include onlyMobile() {
    width: 100%;
    height: 100%;
  }
}