@import '/src/styles/color';
@import '/src/styles/size';
@import '/src/styles/typography';
@import '/src/styles/mixin';

.STIconArrowRight {
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.STUMDropdown {
  position: fixed;
  z-index: 99999;
  bottom: 40px;
  left: 254px;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0px 32px 64px -12px rgb(37 38 46 / 10%);
  border-radius: 16px;
  background-color: $color-neutral-theme-700 !important;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include tablet() {
    left: 260px;
    bottom: 40px;
  }

  @include onlyMobile() {
    left: 12px;
    bottom: 108px;
  }
}

.STUMDropdownMinimized {
  bottom: 40px;
  left: 100px;
}

.STUM {
  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;
  border-radius: 16px;
  padding: 0 8px; // ${theme.spacing(0, 2)};
  margin-top: 40px; // ${theme.spacing(5)};
  cursor: pointer;
  background-color: $color-neutral-theme-600;

  display: flex;
  align-items: center;

  >svg {
    path {
      fill: $color-neutral-theme-50;
    }
  }

  @media (max-width: 1024px) {
    .user-name {
      display: none;
    }
    padding: 0px 8px;
  }
}

.STInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  gap: 12px; // ${theme.spacing(1.5)}
}

.STUMDropdownItem {
  cursor: pointer;
  padding: 8px;
  border-radius: 12px;

  display: flex;
  align-items: center;
  gap: 8px;
  color: $color-neutral-theme-300;

  @extend .body2-medium;

  .icon {
    path {
      fill: $color-neutral-theme-300;
    }
  }

  &:hover {
    background: $color-neutral-theme-500;

    span {
      color: $color-neutral-theme-50;
    }

    .icon {
      path {
        fill: $color-neutral-theme-50;
      }
    }
  }
}
