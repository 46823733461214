@import '/src/styles/color';

.banner {
  max-width: 350px;
  background: $color-neutral-theme-900;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.20), 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
  border-radius: 12px;
  padding: 12px 16px;

  display: flex;
  flex-direction: column;
  gap: 4px;
}
