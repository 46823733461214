@import '/src/styles/variables';

.publicHeader {
  position: relative;
  z-index: 999;
  width: 100%;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #FFF;
  display: none;

  @include onlyMobile() {
      display: flex;
  }
}

.logo {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  background-color: #FFF;
  height: 82px;
  border-bottom: 1px solid $neutral-200;

  .IconMenu {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 39px;

  >svg {
    cursor: pointer;

    path {
      fill: var(--color-neutral-theme-200);
    }
  }
}

.Dropdown {
  position: absolute;
  z-index: 2;
  top: 82px;
  right: 0;
  width: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 0px 0px 32px 32px;
  padding: 0px 16px 16px 16px;

  .DropdownItem {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background-color: $color-neutral-theme-100;
    }
  }

  .ButtonSection {
    width: 100%;
    gap: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .Button {
      width: 100%;

      &.VibeBtn {
        background-color: $color-neutral-theme-700 !important;
        color: #fff !important;
        &:hover {
          background-color: $color-neutral-theme-500 !important;
        }
      }
    }
  }
}