@import '/src/styles/color';

.vvcAd {
  position: relative;
  display: flex;
  width: 236px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 24px;
  background-color: $color-purple-200;
  margin-bottom: -16px;
}

.recordBtn {
  height: 40px;
  border-radius: 12px;
}

.creditText {
  color: $color-purple-400;
}

.btnClose {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 24px;
  height: 24px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: $color-neutral-theme-50;
  outline: none;
  border: none;
  box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);
  transition: all 0.3s ease-in-out;

  svg {
    color: $color-black;
    flex-shrink: 0;
    width: 12px;
  }

  &:hover {
    background: $color-neutral-theme-200;
  }
}