@import '/src/styles/mixin';
@import '/src/styles/color';

.slides {
  box-shadow: 0px 40px 80px -12px rgba(37, 38, 46, 0.10);
  width: 60vw;
  overflow: hidden;

  @include mediaQueryMax($screenLarge) {
    width: 70vw;
    box-shadow: none;
  }

  border-radius: 18px;
}

.slide {
  .cover {
    aspect-ratio: 796 / 416;
    border: 4px solid $color-primary-600;
    overflow: hidden;
    border-radius: 18px;
  }

  .title {
    text-transform: capitalize;
  }
}

.navBtn {
  outline: none;
  border: 0;
  background-color: $neutral-100;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $neutral-200;
  }

  &.next {
    transform: rotate(180deg);
    background-color: $color-primary-500;

    @include mediaQueryMax($screenMediumSmall) {
      // display: none;
    }

    &:hover {
      background-color: $color-primary-400;
    }
  }

  &.prev {
    @include mediaQueryMax($screenMediumSmall) {
      display: none;
    }
  }

  &.disabled {
    opacity: 0;
    cursor: auto
  }
}

.iconBottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  .dots {
    flex: 1;
    justify-content: center;
    align-items: center;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $neutral-200;
      transition: all 0.3s ease-in-out;

      &.active {
        background-color: $color-primary-600;
      }
    }
  }
}

.startBtn {
  padding: 16px 32px !important;
  border-radius: 16px;
}

.bottomBtn {
  position: fixed;
  right: 40px;
  bottom: 40px;

  .skipBtn {
    border-radius: 12px;
    padding: 8px 16px;
    opacity: 1;

    &.isHidden {
      opacity: 0;
    }
  }

}

.onboardingTitleWrapper {
  margin: 0 auto;
}

.onboardingTitle {
  max-width: 572px;
}