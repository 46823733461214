@import '/src/styles/color';
@import '/src/styles/mixin';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 150px;
}

.buttonSelect {
  display: flex;
  height: 56px;
  padding: 14px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  background-color: $color-neutral-theme-100;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 0 1px $color-neutral-theme-200;
  }
}

.menu {
  [class*='MuiPaper-root'] {
    border-radius: 12px;
    margin-top: 10px;
    box-shadow: 0px 40px 80px -12px rgba(37, 38, 46, 0.10);
    border: 1px solid $color-neutral-theme-200;
    padding: 12px 8px;
  }
}

.menuItem {
  padding: 8px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  align-items: flex-start !important;
  width: 100% !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  border-radius: 12px !important;

  &:hover {
    background-color: $color-neutral-theme-100 !important;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
}

.title {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: $color-neutral-theme-300;

  &::after {
    content: '*';
    color: $color-negative-500;
  }
}