@import '/src/styles/typography.scss';
@import '/src/styles/color.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 8px 32px;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: $color-neutral-theme-700;
  }
}

.containerMinimize {
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  width: 48px;
}

.title {
  min-width: 78px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  @extend .body1-bold;
}

.minimizedBadge {
  position: absolute;
  top: -10px;
  left: 50%;
}