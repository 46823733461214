@import '/src/styles/color';

.toast {
  width: 100%;
  min-height: 32px;
  background: $color-neutral-theme-900;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.20), 0px 4px 8px 0px rgba(0, 0, 0, 0.30);
  border-radius: 12px;
  padding: 4px 16px;

  display: flex;
  justify-content: space-between;
  gap: 16px;
}
