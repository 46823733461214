@import 'src/styles/color';
@import '/src/styles/mixin';

$paddingX: 16px;

.container {
  width: 295px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 14px 10px 50px 4px rgba(107, 107, 107, 0.25);
  overflow: hidden;
  z-index: 99999999;

  @include onlyMobile() {
    display: none !important;
  }
}

.containerWidthAuto {
  width: auto !important;
}

.header {
  padding: 14px $paddingX;
  background-color: $color-primary-400;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &_title {
    color: $color-neutral-theme-900;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    flex: 1;
  }

  &_action {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    gap: 8px;
  }

  &_action_button {
    padding: 8px;
    border-radius: 12px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    background-color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.body {
  flex: 1;
  padding: 16px $paddingX;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  gap: 8px;
  max-height: 365px;
  overflow: hidden;
}

.minimizedContainer {
  display: inline-flex;
  padding: 12px 18px;
  align-items: center;
  gap: 6px;

  border-radius: 20px;
  background: $color-primary-400;

  box-shadow: 0px 8px 16px 0px rgba(37, 38, 46, 0.20);

  cursor: pointer;

  &:hover {
    background-color: $color-primary-500;
  }
}

.minimizedTitle {
  color: $color-neutral-theme-900;
  font-size: 14px;
  font-weight: 600;
}