h1 {
  font-weight: 900;
  font-size: 96px;
  line-height: 110px;
  letter-spacing: -0.02em;
}

h2 {
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  letter-spacing: -0.02em;
}

h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.02em;

  &.mobile {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.48px;
  }
}

h5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
}

.subtitle-regular {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.subtitle-bold {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
}

.subtitle--medium {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}

.body1-regular {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.body1-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.body1-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
}

.body2-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.body2-medium {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.body2-bold {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
}

.meta-regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.meta-medium {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.meta-bold {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.tiny-semibold {
  font-weight: 600;
  font-size: 9px;
  line-height: 10px;
}

.font-medium {
  font-weight: 500;
}