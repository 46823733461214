@import '/src/styles/color';
@import '/src/styles/mixin';

.CountDown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 96px;
  font-weight: 900;
  z-index: 2;

  p {
    color: #fff;
  }
}