.layout {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  overflow: hidden;
  background-color: var(--color-neutral-theme-100);
}

.loading {
  pointer-events: 'none';
}